<template>
  <div style="height: 100%;">
    <main-navigation :hide-nav="true"> </main-navigation>

    <div class="app-layout">
      <div class="page-wrap">
        <div class="main-page-column pt-0">
          <div class="scroll-container pt-0">
            <v-row class="d-none d-sm-flex mb-3">
              <v-col>
                <h4 class="text-uppercase">My Apps</h4>
                <v-row justify="start" no-gutters>
                  <v-card
                    v-for="app in orderBy(userApps, 'name')"
                    :key="app.id"
                    class="card-bordered mt-3 mr-3"
                    outlined
                    hover
                    :to="app.url"
                    style="width: 150px;"
                  >
                    <v-card-text>
                      <v-row justify="center" align="center" class="mb-3">
                        <v-icon>{{ app.icon }}</v-icon>
                      </v-row>
                      <div class="text-center font-weight-bold">
                        {{ app.name }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col
                v-if="currentUser && currentUser.userRole !== 'user'"
                cols="12"
                sm="12"
                md="6"
                lg="4"
                xl="4"
              >
                <v-card
                  :loading="$apollo.queries.myOpenReviews.loading"
                  outlined
                >
                  <v-card-title class="subtitle-1 text-uppercase"
                    >My Reviews</v-card-title
                  >
                  <v-data-table
                    :headers="reviewHeaders"
                    :items="myOpenReviews"
                    :hide-default-footer="myOpenReviews.length < 11"
                    :items-per-page="10"
                    :class="[
                      myOpenReviews && myOpenReviews.length > 0
                        ? 'plain-table'
                        : ''
                    ]"
                    @click:row="navToReview"
                  >
                    <template v-slot:item.status="{ item }">
                      <v-chip :color="stateColor(item.status)" small label>{{
                        item.status === 'open' ? 'review' : item.status
                      }}</v-chip>
                    </template>
                    <template v-slot:item.departmentName="{ item }">
                      <v-chip
                        color="primary"
                        small
                        label
                        outlined
                        class="mr-3"
                        >{{ item.departmentName }}</v-chip
                      >
                    </template>
                    <template v-slot:no-data
                      ><span
                        >Great, you've worked through all your reviews!</span
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-col> -->
              <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                <v-card :loading="$apollo.queries.myProjects.loading" flat>
                  <v-card-title class="subtitle-1 text-uppercase"
                    >My Projects</v-card-title
                  >
                  <v-simple-table class="plain-table">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in myProjects.slice(0, 5)"
                          :key="item.id"
                          @click="navToProject(item)"
                        >
                          <td class="py-2">
                            <v-badge
                              :color="stateColor(item.status)"
                              dot
                              left
                              inline
                            >
                              <div
                                style="whitespace: nowrap"
                                class="body-2 primary--text font-weight-medium pl-2 text-truncate"
                              >
                                {{ item.name }}
                              </div>
                            </v-badge>
                          </td>
                          <td>
                            <v-row justify="end" class="pr-3">
                              <v-chip
                                x-small
                                color="info lighten-2 font-weight-bold upperCaseSpacing"
                                >{{ item.department.name }}</v-chip
                              >
                              <!-- <v-chip
                                :color="stateColor(item.status)"
                                x-small
                                class="ml-2 mr-2 text-uppercase"
                                >{{ item.status }}</v-chip
                              > -->
                            </v-row>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <v-col v-if="false" cols="12" sm="12" md="6" lg="4" xl="4">
                <h4 class="text-uppercase">Purchase Orders</h4>
                <v-row justify="start" no-gutters></v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import MainNavigation from '@/components/core/MainNavigation.vue'
// import MY_OPEN_REVIEWS from '@/graphql/MyOpenReviews.gql'
import MY_PROJECTS from '@/graphql/MyProjects.gql'

export default {
  name: 'Dashboard',
  components: {
    MainNavigation
  },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    drawer: true,
    reviewHeaders: [
      { text: 'Type', value: 'auditType', sortable: true },
      { text: 'Name', value: 'reviewAsetName', sortable: false },
      { text: 'Department', value: 'departmentName', sortable: false },
      { text: 'Status', value: 'status', sortable: false }
    ],
    projectHeaders: [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Department', value: 'department', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Created On', value: 'createdAt', sortable: true }
    ],
    myOpenReviews: [],
    myProjects: []
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    userApps() {
      return this._.filter(this.$store.state.userApps, function(item) {
        return item.active === true
      })
    }
  },
  apollo: {
    // myOpenReviews: MY_OPEN_REVIEWS,
    myProjects: MY_PROJECTS
  },
  methods: {
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review' || item === 'reviewadjustment') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    navToReview(item) {
      if (item.auditType === 'PROJECT') {
        this.$router.push({
          name: 'ProjectDetail',
          params: { projectID: item.reviewAssetID }
        })
      } else if (item.auditType === 'BUDGET') {
        this.$router.push({
          name: 'BudgetDetail',
          params: { budgetID: item.reviewAssetID }
        })
      } else if (item.auditType === 'PURCHASEORDER') {
        this.$router.push({
          name: 'PurchaseOrderDetail',
          params: { purchaseOrderID: item.reviewAssetID }
        })
      }
    },
    navToProject(item) {
      this.$router.push({
        name: 'ProjectDetail',
        params: { projectID: item.id }
      })
    }
  }
}
</script>

<style></style>
